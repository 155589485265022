import { cn } from '../../utils/cn';

interface FormContainerProps {
  children: React.ReactNode;
  isModal?: boolean;
}

export default function FormContainer({
  isModal,
  children,
}: FormContainerProps) {
  return (
    <div
      className={cn(
        'flex flex-col h-fit bg-white rounded-lg px-8 py-6 gap-5 w-full max-w-[480px]',
        isModal && 'w-[480px]'
      )}
    >
      {children}
    </div>
  );
}
